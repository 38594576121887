/* .sidebar {

    background: #4f5d73 !important;
  }

.sidebar .nav-title {
    padding: 0.75rem 1rem;
    font-size: 80%;
    font-weight: 700;
    color: #be9a31 !important;
    text-transform: uppercase;
}

.sidebar .nav-link {
    display: block;
    padding: 0.75rem 1rem;
    color: #b7d47f ;
    text-decoration: none;
    background: transparent;
}
.sidebar .nav-link .text-white{
    color: #fff !important;

}
.sidebar .nav-link.nav-link-info {
    background: #000000 !important;
}
.sidebar .nav-link.nav-link-info:hover {
    background: #ffc00038 !important;
} */

/* new sidebar design */

/* Sidebar customize */
.sidebar {
  background: #4f5d73 !important;
}

.sidebar .sidebar-minimizer {
  background-color: #303c54;
}
.sidebar .nav-link {
  color: #e7e4e8 !important;
  font-weight: 500;
}

.sidebar .nav-dropdown.open .nav-link {
  font-weight: 500;
  background: #303c50 !important;
}

.sidebar .nav-title {
  font-size: 80%;
  font-weight: 700;
  color: #b1b7c1 !important;
}

.sidebar .nav-link:hover {
  background: #3b4659 !important;
}
.sidebar .nav-link.nav-link-info {
  background: #3b4659 !important;
}

.sidebar .nav-dropdown.open .nav-link:hover {
  background: #3b4659 !important;
}

/* .sidebar {
    display: flex;
    flex-direction: column;
    padding: 0;
    color: #fff;
    background: #181b1e !important;
} */

.required-cls {
  color: red;
  font-size: 12px;
}

.loader {
  margin-top: 10%;
  margin-left: 30%;
  width: 5% !important;
  height: 10%;
  /* border-radius: calc(0.25rem - 1px) !important; */
  /* background-color: #000000; */
  z-index: 5000;
}

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* /* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  /* box-shadow: inset 0 0 5px grey;  */
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #C1C1C1; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bdbaba; 
} 

.dClnOm {
  position: fixed!important;
  bottom: 20px!important;
}